import MarkdownTemplate, {
  MarkdownTemplateData,
  MarkdownTemplateProps
} from "./markdown-template"

import { IGatsbyImageData } from "gatsby-plugin-image"
import OurApproachRelatedPages from "../../components/our-approach/OurApproachRelatedPages"
import React from "react"
import { graphql } from "gatsby"
import { routesObject } from "@life-without-barriers/react-components"

interface Data extends MarkdownTemplateData {
  bannerImage: IGatsbyImageData
}

interface OurApproachProps extends MarkdownTemplateProps {
  data: Data
}

const OurApproach = (props: OurApproachProps) => (
  <MarkdownTemplate
    {...props}
    ogImage={props.data.bannerImage}
    breadcrumbs={[
      routesObject.home,
      {
        to: "/our-approach",
        text: "Our approach",
        title: "Return to parent page"
      },
      {
        to: "..",
        text: "Child, youth and family",
        title: "Return to main page"
      }
    ]}
    afterContent={
      <OurApproachRelatedPages currentPathname={props.location.pathname} />
    }
  />
)

export const query = graphql`
  query ($slug: String) {
    ...ContentMarkdownQueryFragment
    bannerImage: file(
      relativePath: { regex: "/img-our-approach-banner.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`
export default OurApproach
